<template>
  <div class="layout-page">
    <el-container>
      <el-aside style="height: 100%" width="auto"><LayoutMenu /></el-aside>
      <el-container>
        <el-header
          class="flex items-center"
          style="box-shadow: 0 4px 4px rgba(0, 21, 41, 0.08)"
          ><LayoutHeader
        /></el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer>
          <div class="footer">
            <a href="https://beian.miit.gov.cn/">
            蜀ICP备2024077274号-1
            </a>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LayoutMenu from "./components/Menu/index.vue";
import LayoutHeader from "./components/Header/index.vue";
export default {  
  components: {
    LayoutMenu,
    LayoutHeader,
  },
  data() {
    return {};
  },
  computed: {
    isCollapse() {
      return this.$store.state.config.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-page,
.el-container {
  height: 100%;
}

.footer {
  text-align: center;
  padding: 10px 0;
  color: #999;
}
</style>
